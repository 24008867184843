define(['app', '$window', 'siteObj'], function(app, $window, siteObj) {



  var athenaProductBlock = function() {
    var self = this;
    const DATA_OPTION_ID = 'data-option-id';
    const DATA_LINKED_SWATCH = 'data-linked-swatch';
    const DATA_ALT_SRC = 'data-alt-src';
    const DATA_TRACK_HOVER = 'data-component-tracked-hovered';
    const DATA_TRACK_FOCUS = 'data-component-tracked-focused';
    const DATA_CONTEXT = 'data-context';


    self.app = app; // locally reference app object

    var _config = {
      buttonSelector: '[data-js-element=button]',
      buttonClickEvent: 'athenaProductBlock-buttonClick',
      quickViewIconSelector: '[data-js-element=quickViewIcon]',
      quickViewIconClickEvent: 'athenaProductBlock-quickViewClick',
      productDataSelector: '[data-js-element=athenaProductBlock_data]',
      colorSwatch: '.colorSwatches_swatch',
      image: '.athenaProductBlock_image',
      imageLink: '.athenaProductBlock_linkImage',
      rolloverWrapper:'.athenaProductBlock_imageContainer',
    };

    var _init = function(element) {
      self.element = element;
      self.masterProductId = self.element.getAttribute('rel');
      self.button = self.element.querySelector(self.config.buttonSelector);
      self.quickViewIcon = self.element.querySelector(self.config.quickViewIconSelector);
      self.productData = self.element.querySelector(self.config.productDataSelector);
      self.colorSwatches = self.element.querySelectorAll(self.config.colorSwatch);
      self.image = self.element.querySelector(self.config.image);
      self.imageLink = self.element.querySelector(self.config.imageLink);
      self.rolloverWrapper = self.element.querySelector(self.config.rolloverWrapper);
      self.originalSrc = self.image.src;
      self.bind();
      return self;
    };


    var _bind = function() {
      if(siteObj.config.useGa4EnhancedEcom === true) {
        const itemsObjectArray = [{
          'item_name': self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-title') || " ".replace(/&#039;|'/g, ""),
          'item_id': self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-id') ? self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-id').toString() : '',
          'price': self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-price').replace(/[^\d.-]/g, ''),
          'item_brand': self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-brand'),
          'item_category': self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-category'),
          'item_list_name': siteObj.sectionName,
          'index': self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-position'),
          'brand': self.element.querySelector('.js-enhanced-ecommerce-data').getAttribute('data-product-brand')
        }];

        self.element.addEventListener('click', (e) => {
          const quickBuy = self.element.querySelector('.productQuickbuy')
          const moreInfoButton = e.target.classList.contains('athenaProductBlock_button-moreInfo');
          
          if(quickBuy) {
            if(!moreInfoButton && !quickBuy.contains(e.target)) {
              productBlockClicked('select_item', itemsObjectArray);
            }
          } else {
            if(!moreInfoButton) {
              productBlockClicked('select_item', itemsObjectArray);
            }
          }
          
          if(e.target.classList.contains('productAddToBasket-buyNow')) {
            productBlockClicked('add_to_cart', itemsObjectArray);
          }
        })
      }

      if (self.button) {
        self.button.addEventListener('click', self.buttonClicked);
      }
      if (self.quickViewIcon) {
        self.quickViewIcon.addEventListener('click', self.quickViewIconClicked);
      }

      for (let colorSwatch of self.colorSwatches) {
        colorSwatch.addEventListener('click', self.colorSwatchClicked.bind(null, colorSwatch));
      }

      if ($window.innerWidth > 900) {
        if (self.image.hasAttribute(DATA_ALT_SRC) && self.image.getAttribute(DATA_ALT_SRC) !== '') {
          self.rolloverWrapper.addEventListener('mouseover', self.imageRolloverIn);
          self.imageLink.addEventListener('focusin',self.imageRolloverIn);
          self.rolloverWrapper.addEventListener('mouseleave', self.imageRolloverOut);
          self.imageLink.addEventListener('focusout', self.imageRolloverOut);

          if (!self.rolloverWrapper.hasAttribute(DATA_TRACK_HOVER)) {
            self.rolloverWrapper.setAttribute(DATA_TRACK_HOVER, "");
            self.rolloverWrapper.setAttribute(DATA_CONTEXT, "imageRollover-hover");
          }

          if (!self.imageLink.hasAttribute(DATA_TRACK_FOCUS)) {
            self.imageLink.setAttribute(DATA_TRACK_FOCUS, "")
            self.imageLink.setAttribute(DATA_CONTEXT, "imageRollover-focus");
          }
        }
      }
    };

    const productBlockClicked = (event, itemsObjectArray) => {
      app.publish('ga4tracking/record', 'ecom_event', event, siteObj.currencyType, itemsObjectArray);
    }

    var _buttonClicked = function(event) {
      event.preventDefault();
      self.app.publish(self.config.buttonClickEvent);
    };

    var _quickViewIconClicked = function(event) {
      //This currently triggers the old quickview component
      event.preventDefault();
      self.app.publish(self.config.quickViewIconClickEvent);
    };

    var _colorSwatchClicked = (colorSwatch) => {
      if (colorSwatch.hasAttribute(DATA_LINKED_SWATCH)) {
        var clickedProductId = colorSwatch.getAttribute(DATA_OPTION_ID);
        app.publish('tracking/record', 'Linked SKU Engagement',
          'Clicked away from ' + self.masterProductId, 'Clicked on ' + clickedProductId);
      }
    };

    var _imageRolloverIn = function() {
      self.image.src = self.image.getAttribute(DATA_ALT_SRC);
    };

    var _imageRolloverOut = function() {
      self.image.src = self.originalSrc;
    };

    self.config = _config;
    self.init = _init;
    self.bind = _bind;
    self.imageRolloverIn = _imageRolloverIn;
    self.imageRolloverOut = _imageRolloverOut;
    self.colorSwatchClicked = _colorSwatchClicked;
    self.buttonClicked = _buttonClicked;
    self.quickViewIconClicked = _quickViewIconClicked;
    self.productBlockClicked = productBlockClicked;
  };

  return athenaProductBlock;
});
